import { render, staticRenderFns } from "./projectcoordinators_invoice.vue?vue&type=template&id=6bcd57ab&scoped=true&"
import script from "./projectcoordinators_invoice.vue?vue&type=script&lang=js&"
export * from "./projectcoordinators_invoice.vue?vue&type=script&lang=js&"
import style0 from "./projectcoordinators_invoice.vue?vue&type=style&index=0&id=6bcd57ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcd57ab",
  null
  
)

export default component.exports